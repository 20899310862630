<template>
  <section>
    <b-overlay :show="loading">
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="order === null"
      >
        <h4 class="alert-heading">
          خطا در دریافت اطلاعات سفارش
        </h4>
        <div class="alert-body">
          سفارش با این آی دی یافت نشد!
          <b-link
            class="alert-link"
            :to="{ name: 'orders'}"
          >
            لیست سفارشات
          </b-link>
          برای دیگر سفارشات.
        </div>
      </b-alert>

      <b-card
        v-if="order"
      >

        <!--        customer details-->
        <div>

          <b-row>
            <b-col>
              <h4>مشخصات سفارش دهنده</h4>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col class="mt-1 sm">
              <span class="text-black-50">نام و نام خانوادگی : </span>
              <span>{{ order.full_name }}</span>
            </b-col>
            <b-col class="mt-1 sm">
              <span class="text-black-50"> شماره موبایل : </span>
              <span>{{ order.mobile }}</span>
            </b-col>
            <b-col class="mt-1 sm">
              <span class="text-black-50"> شماره تلفن : </span>
              <span>{{ order.phone }}</span>
            </b-col>
            <b-col class="mt-1 sm">
              <span class="text-black-50">  وضعیت اکانت : </span>
              <b-badge
                pill
                :variant="'light-'+accountStatusVariant(order.user_account_status)"
                class="text-capitalize"
              >
                {{ accountStatusLabel(order.user_account_status) }}
              </b-badge>
            </b-col>
          </b-row>

        </div>

        <hr>

        <!--        order details crypto buy-->
        <div v-if="order.method === 'crypto' && order.type==='buy'">

          <!--          order details section-->
          <div>

            <b-row class="mt-2">
              <b-col>
                <h4>مشخصات سفارش </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col class="mt-1 sm">
                <span class="text-black-50">شماره سفارش: </span>
                <span> {{ order.order_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">نوع سفارش: </span>
                <b-badge
                  pill
                  :variant="'light-'+orderTypeVariant(order.type)"
                  class="text-capitalize"
                >
                  {{ orderTypeLabel(order.type) + ' ' + order.crypto_persian_name }}
                </b-badge>
              </b-col>

              <b-col
                class="mt-1 sm"
              >
                <span class="text-black-50">روش پرداخت : </span>
                <b-badge
                  pill
                  :variant="'light-'+orderPayMethodVariant(order.pay_method)"
                  class="text-capitalize"
                >
                  {{ orderPayMethodLabel(order.pay_method) }}
                </b-badge>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">تاریخ سفارش: </span>
                <span> {{ order.jalali_created_at }}</span>
              </b-col>

            </b-row>

            <b-row>

              <b-col class="mt-2 sm">
                <span class="text-black-50"> تعداد: </span>
                <span> {{ order.amount }}</span>
                <b-badge
                  pill
                  variant="light-info"
                >
                  {{ order.crypto_symbol }}
                </b-badge>

              </b-col>

              <!--            if order type buy-->
              <b-col
                v-if="order.type === 'buy'"
                class="mt-2 sm"
              >
                <span class="text-black-50"> قیمت واحد: </span>
                <span> {{ Number(order.purchase_price_from_us).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

              <!--            if order type sell-->
              <b-col
                v-if="order.type === 'sell'"
                class="mt-2 sm"
              >
                <span class="text-black-50"> قیمت واحد:  </span>
                <span> {{ Number(order.sale_price_from_us).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50"> مبلغ کل پرداختی: </span>
                <span> {{ Number(order.cost).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50">   وضعیت سفارش : </span>
                <b-badge
                  pill
                  :variant="'light-'+orderStatusVariant(order.status)"
                  class="text-capitalize"
                >
                  {{ orderStatusLabel(order.status) }}
                </b-badge>
              </b-col>

            </b-row>

            <b-row>

              <b-col
                v-if="order.type === 'buy'"
                class="mt-2 sm"
              >
                <span class="text-black-50">   آدرس کیف پول : </span>
                <span> {{ order.wallet_address || '----' }}</span>
              </b-col>

              <b-col
                v-if="order.type === 'sell'"
                class="mt-2 sm"
              >
                <span class="text-black-50">      لینک تراکنش(TxId) :  </span>
                <span> {{ order.txid }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50">     توضیحات پرداخت: </span>
                <span> {{ order.description || '----' }}</span>
              </b-col>

            </b-row>

          </div>

          <div v-if="order.pay_method === 'gate'">
            <b-row
              class="mt-2"
            >
              <hr>
              <b-col>
                <h4>مشخصات تراکنش (درگاه بانکی) </h4>
              </b-col>
            </b-row>
            <b-row>
              جزییات پرداخت
            </b-row>
          </div>

          <div v-if="order.pay_method === 'wallet'">

            <hr>

            <b-row
              class="mt-2"
            >
              <b-col>
                <h4>مشخصات تراکنش (کیف پول) </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col class="mt-1 sm">
                <span class="text-black-50">  موجودی کیف پول کاربر: </span>
                <span>{{ Number(order.tomman_inventory).toLocaleString() }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50"> مبلغ کل سفارش : </span>
                <span> {{ Number(order.cost).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

            </b-row>

          </div>

          <!--          action -->
          <div>
            <hr>
            <b-row
              v-if="order.pay_method === 'wallet'"
              class="mt-2"
            >
              <b-col>
                <h4>عملیات</h4>
              </b-col>

            </b-row>

            <b-row class="mt-2">

              <b-col>
                <span class="text-black-50">
                  وضعیت سفارش :
                </span>
              </b-col>

              <b-col>
                <v-select
                  v-model="selected"
                  dir="rtl"
                  :options="statusOption"
                  label="title"
                />
              </b-col>

              <b-col>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="changeStatus"
                >
                  ثبت تغییرات
                </b-button>
              </b-col>

            </b-row>

          </div>

        </div>
        <!--        order details crypto sell-->
        <div v-if="order.method === 'crypto' && order.type==='sell'">

          <div>
            <b-row class="mt-2">
              <b-col>
                <h4>مشخصات سفارش </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col class="mt-1 sm">
                <span class="text-black-50">شماره سفارش: </span>
                <span> {{ order.order_number }}</span>
              </b-col>
              <b-col class="mt-1 sm">
                <span class="text-black-50">نوع سفارش: </span>
                <b-badge
                  pill
                  :variant="'light-'+orderTypeVariant(order.type)"
                  class="text-capitalize"
                >
                  {{ orderTypeLabel(order.type) + ' ' + order.crypto_persian_name }}
                </b-badge>
              </b-col>
              <b-col class="mt-1 sm">
                <span class="text-black-50">روش پرداخت : </span>
                <b-badge
                  pill
                  :variant="'light-'+orderPayMethodVariant(order.pay_method)"
                  class="text-capitalize"
                >
                  {{ orderPayMethodLabel(order.pay_method) }}
                </b-badge>
              </b-col>
              <b-col class="mt-1 sm">
                <span class="text-black-50">تاریخ سفارش: </span>
                <span> {{ order.jalali_created_at }}</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mt-2 sm">
                <span class="text-black-50"> تعداد: </span>
                <span> {{ order.amount + ' ' + order.crypto_symbol }}</span>
              </b-col>

              <b-col
                v-if="order.type === 'buy'"
                class="mt-2 sm"
              >
                <span class="text-black-50"> قیمت واحد: </span>
                <span> {{ Number(order.purchase_price_from_us).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

              <b-col
                v-if="order.type === 'sell'"
                class="mt-2 sm"
              >
                <span class="text-black-50"> قیمت واحد:  </span>
                <span> {{ Number(order.sale_price_from_us).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50"> مبلغ کل پرداختی: </span>
                <span> {{ Number(order.cost).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50">   وضعیت سفارش : </span>
                <b-badge
                  pill
                  :variant="'light-'+orderStatusVariant(order.status)"
                  class="text-capitalize"
                >
                  {{ orderStatusLabel(order.status) }}
                </b-badge>
              </b-col>

            </b-row>

            <b-row>
              <b-col
                v-if="order.type === 'buy'"
                class="mt-2 sm"
              >
                <span class="text-black-50">   آدرس کیف پول : </span>
                <span> {{ order.wallet_address || '----' }}</span>
              </b-col>
              <b-col
                v-if="order.type === 'sell'"
                class="mt-2 sm"
              >
                <span class="text-black-50">      لینک تراکنش(TxId) :  </span>
                <span> {{ order.txid }}</span>
              </b-col>
              <b-col class="mt-2 sm">
                <span class="text-black-50">     توضیحات پرداخت: </span>
                <span> {{ order.description || '----' }}</span>
              </b-col>
            </b-row>
          </div>

          <div v-if="order.pay_method === 'wallet'">

            <hr>

            <b-row
              class="mt-2"
            >
              <b-col>
                <h4>مشخصات تراکنش (کیف پول) </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col class="mt-1 sm">
                <span class="text-black-50">  موجودی کیف پول کاربر: </span>
                <span>{{ Number(order.tomman_inventory).toLocaleString() }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50"> مبلغ کل سفارش : </span>
                <span> {{ Number(order.cost).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

            </b-row>

          </div>

          <div v-if="order.pay_method === 'bank_account'">

            <hr>

            <b-row
              class="mt-2"
            >
              <b-col>
                <h4>مشخصات تراکنش (انتقال به کارت بانکی کاربر) </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col class="mt-1 sm">
                <span class="text-black-50">  نام بانک : </span>
                <span>{{ order.user_bank_name || 'ثبت نشده است!' }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره حساب: </span>
                <span>{{ order.user_account_number || 'ثبت نشده است!' }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره کارت: </span>
                <span>{{ order.user_card_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره شبا: </span>
                <span>{{ order.user_shaba_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">   وضعیت کارت بانکی کاربر: </span>
                <b-badge
                  pill
                  :variant="'light-'+bankAccountStatusVariant(order.user_bank_account_status)"
                  class="text-capitalize"
                >
                  {{ bankAccountStatusLabel(order.user_bank_account_status) }}
                </b-badge>
              </b-col>

            </b-row>

          </div>

          <div v-if="order.pay_method === 'other_account'">

            <hr>

            <b-row
              class="mt-2"
            >
              <b-col>
                <h4>مشخصات تراکنش (واریز به حساب دیگران) </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره کارت: </span>
                <span>{{ order.other_card_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  نام و فامیل صاحب حساب : </span>
                <span>{{ order.other_fullname }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">   نام بانک: </span>
                <span>{{ order.other_bank_name || 'ثبت نشده است!' }}</span>
              </b-col>

            </b-row>

            <b-row>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره شبا: </span>
                <span>{{ order.other_shaba_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره حساب: </span>
                <span>{{ order.other_account_number || 'ثبت نشده است!' }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">   توضیحات: </span>
                <span>{{ order.description }}</span>
              </b-col>

            </b-row>

          </div>

          <!--          action -->
          <div>
            <hr>
            <b-row
              v-if="order.pay_method === 'wallet'"
              class="mt-2"
            >
              <b-col>
                <h4>عملیات</h4>
              </b-col>

            </b-row>

            <b-row class="mt-2">

              <b-col>
                <span class="text-black-50">
                  وضعیت سفارش :
                </span>
              </b-col>

              <b-col>
                <v-select
                  v-model="selected"
                  dir="rtl"
                  :options="statusOption"
                  label="title"
                />
              </b-col>

              <b-col>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="changeStatus"
                >
                  ثبت تغییرات
                </b-button>
              </b-col>

            </b-row>

          </div>

        </div>

        <!--        order details voucher but-->
        <div v-if="order.method === 'voucher' && order.type === 'buy'">
          <b-row class="mt-2">
            <b-col>
              <h4>مشخصات سفارش </h4>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col class="mt-1 sm">
              <span class="text-black-50">شماره سفارش: </span>
              <span> {{ order.order_number }}</span>
            </b-col>
            <b-col class="mt-1 sm">
              <span class="text-black-50">نوع سفارش: </span>
              <b-badge
                pill
                :variant="'light-'+orderTypeVariant(order.type)"
                class="text-capitalize"
              >
                {{ orderTypeLabel(order.type) + ' ' + order.crypto_persian_name }}
              </b-badge>
            </b-col>
            <b-col class="mt-1 sm">
              <span class="text-black-50">روش پرداخت : </span>
              <b-badge
                pill
                :variant="'light-'+orderPayMethodVariant(order.pay_method)"
                class="text-capitalize"
              >
                {{ orderPayMethodLabel(order.pay_method) }}
              </b-badge>
            </b-col>
            <b-col class="mt-1 sm">
              <span class="text-black-50">تاریخ سفارش: </span>
              <span> {{ order.jalali_created_at }}</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="mt-2 sm">
              <span class="text-black-50"> تعداد: </span>
              <span> {{ order.amount + ' ' + order.crypto_symbol }}</span>
            </b-col>
            <b-col
              v-if="order.type === 'buy'"
              class="mt-2 sm"
            >
              <span class="text-black-50"> قیمت واحد: </span>
              <span> {{ Number(order.purchase_price_from_us).toLocaleString() + ' ' + 'تومان' }}</span>
            </b-col>
            <b-col
              v-if="order.type === 'sell'"
              class="mt-2 sm"
            >
              <span class="text-black-50"> قیمت واحد:  </span>
              <span> {{ Number(order.sale_price_from_us).toLocaleString() + ' ' + 'تومان' }}</span>
            </b-col>
            <b-col class="mt-2 sm">
              <span class="text-black-50"> مبلغ کل پرداختی: </span>
              <span> {{ Number(order.cost).toLocaleString() + ' ' + 'تومان' }}</span>
            </b-col>

            <b-col class="mt-2 sm">
              <span class="text-black-50">   وضعیت سفارش : </span>
              <b-badge
                pill
                :variant="'light-'+orderStatusVariant(order.status)"
                class="text-capitalize"
              >
                {{ orderStatusLabel(order.status) }}
              </b-badge>
            </b-col>

          </b-row>

          <b-row>
            <b-col class="mt-2 sm">
              <span class="text-black-50">     توضیحات پرداخت: </span>
              <span> {{ order.description || '----' }}</span>
            </b-col>
          </b-row>

          <div v-if="order.pay_method === 'wallet'">

            <hr>

            <b-row
              class="mt-2"
            >
              <b-col>
                <h4>مشخصات تراکنش (کیف پول) </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col class="mt-1 sm">
                <span class="text-black-50">  موجودی کیف پول کاربر: </span>
                <span>{{ Number(order.tomman_inventory).toLocaleString() }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50"> مبلغ کل سفارش : </span>
                <span> {{ Number(order.cost).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

            </b-row>

          </div>

          <!--          action -->
          <div>
            <hr>
            <b-row
              v-if="order.pay_method === 'wallet'"
              class="mt-2"
            >
              <b-col>
                <h4>عملیات</h4>
              </b-col>

            </b-row>

            <b-row class="mt-2">

              <b-col>
                <span class="text-black-50">
                  وضعیت سفارش :
                </span>
              </b-col>

              <b-col>
                <v-select
                  v-model="selected"
                  dir="rtl"
                  :options="statusOption"
                  label="title"
                />
              </b-col>

              <b-col>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="changeStatus"
                >
                  ثبت تغییرات
                </b-button>
              </b-col>

            </b-row>

          </div>

        </div>
        <!--        order details voucher sell-->
        <div v-if="order.method === 'voucher' && order.type === 'sell'">

          <div>
            <b-row class="mt-2">
              <b-col>
                <h4>مشخصات سفارش </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col class="mt-1 sm">
                <span class="text-black-50">شماره سفارش: </span>
                <span> {{ order.order_number }}</span>
              </b-col>
              <b-col class="mt-1 sm">
                <span class="text-black-50">نوع سفارش: </span>
                <b-badge
                  pill
                  :variant="'light-'+orderTypeVariant(order.type)"
                  class="text-capitalize"
                >
                  {{ orderTypeLabel(order.type) + ' ' + order.crypto_persian_name }}
                </b-badge>
              </b-col>
              <b-col class="mt-1 sm">
                <span class="text-black-50">روش پرداخت : </span>
                <b-badge
                  pill
                  :variant="'light-'+orderPayMethodVariant(order.pay_method)"
                  class="text-capitalize"
                >
                  {{ orderPayMethodLabel(order.pay_method) }}
                </b-badge>
              </b-col>
              <b-col class="mt-1 sm">
                <span class="text-black-50">تاریخ سفارش: </span>
                <span> {{ order.jalali_created_at }}</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mt-2 sm">
                <span class="text-black-50"> کد ووچر: </span>
                <span> {{ order.voucher_code }}</span>
              </b-col>

              <b-col
                v-if="order.type === 'buy'"
                class="mt-2 sm"
              >
                <span class="text-black-50">  کد فعال سازی: </span>
                <span> {{ order.activation_code }}</span>
              </b-col>

              <b-col
                v-if="order.type === 'sell'"
                class="mt-2 sm"
              >
                <span class="text-black-50">  کد فعال سازی: </span>
                <span> {{ order.activation_code }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50"> مبلغ کل پرداختی: </span>
                <span> {{ Number(order.cost).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50">   وضعیت سفارش : </span>
                <b-badge
                  pill
                  :variant="'light-'+orderStatusVariant(order.status)"
                  class="text-capitalize"
                >
                  {{ orderStatusLabel(order.status) }}
                </b-badge>
              </b-col>

            </b-row>

          </div>

          <div v-if="order.pay_method === 'wallet'">

            <hr>

            <b-row
              class="mt-2"
            >
              <b-col>
                <h4>مشخصات تراکنش (کیف پول) </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col class="mt-1 sm">
                <span class="text-black-50">  موجودی کیف پول کاربر: </span>
                <span>{{ Number(order.tomman_inventory).toLocaleString() }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50"> مبلغ کل سفارش : </span>
                <span> {{ Number(order.cost).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

            </b-row>

          </div>

          <div v-if="order.pay_method === 'bank_account'">

            <hr>

            <b-row
              class="mt-2"
            >
              <b-col>
                <h4>مشخصات تراکنش (انتقال به کارت بانکی کاربر) </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col class="mt-1 sm">
                <span class="text-black-50">  نام بانک : </span>
                <span>{{ order.user_bank_name || 'ثبت نشده است!' }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره حساب: </span>
                <span>{{ order.user_account_number || 'ثبت نشده است!' }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره کارت: </span>
                <span>{{ order.user_card_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره شبا: </span>
                <span>{{ order.user_shaba_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">   وضعیت کارت بانکی کاربر: </span>
                <b-badge
                  pill
                  :variant="'light-'+bankAccountStatusVariant(order.user_bank_account_status)"
                  class="text-capitalize"
                >
                  {{ bankAccountStatusLabel(order.user_bank_account_status) }}
                </b-badge>
              </b-col>

            </b-row>

          </div>

          <div v-if="order.pay_method === 'other_account'">

            <hr>

            <b-row
              class="mt-2"
            >
              <b-col>
                <h4>مشخصات تراکنش (واریز به حساب دیگران) </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره کارت: </span>
                <span>{{ order.other_card_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  نام و فامیل صاحب حساب : </span>
                <span>{{ order.other_fullname }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">   نام بانک: </span>
                <span>{{ order.other_bank_name || 'ثبت نشده است!' }}</span>
              </b-col>

            </b-row>

            <b-row>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره شبا: </span>
                <span>{{ order.other_shaba_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره حساب: </span>
                <span>{{ order.other_account_number || 'ثبت نشده است!' }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">   توضیحات: </span>
                <span>{{ order.description }}</span>
              </b-col>

            </b-row>

          </div>

          <!--          action -->
          <div>
            <hr>
            <b-row
              v-if="order.pay_method === 'wallet'"
              class="mt-2"
            >
              <b-col>
                <h4>عملیات</h4>
              </b-col>

            </b-row>

            <b-row class="mt-3">

              <b-col
                v-if="order.pay_method === 'walllet'"
                md="2"
              >
                <span class="text-black-50">
                  مبلغ سفارش :
                </span>
              </b-col>

              <b-col
                v-if="order.pay_method === 'wallet'"
                md="3"
              >
                <b-form-input
                  id="input-valid1"
                  v-model="voucherCost"
                  :state="voucherCost.length > 0"
                  placeholder="Valid input"
                />
                <b-form-invalid-feedback
                  tooltip
                >
                  لطفا مبلغ کل سفارش را برای واریز به حساب کاربر وارد کنید
                </b-form-invalid-feedback>
              </b-col>

              <b-col md="2">
                <span class="text-black-50">
                  وضعیت سفارش :
                </span>
              </b-col>

              <b-col md="3">
                <v-select
                  v-model="selected"
                  dir="rtl"
                  :options="statusOption"
                  label="title"
                />
              </b-col>

              <b-col>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="changeVoucherStatus"
                >
                  ثبت تغییرات
                </b-button>
              </b-col>

            </b-row>

          </div>

        </div>

        <!--        order details system_payment charge-->
        <div v-if="order.method === 'system_payment' && order.type === 'charge'">

          <b-row class="mt-2">
            <b-col>
              <h4>مشخصات سفارش </h4>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col class="mt-1 sm">
              <span class="text-black-50">شماره سفارش: </span>
              <span> {{ order.order_number }}</span>
            </b-col>
            <b-col class="mt-1 sm">
              <span class="text-black-50">نوع سفارش: </span>
              <b-badge
                pill
                :variant="'light-'+orderTypeVariant(order.type)"
                class="text-capitalize"
              >
                {{ orderTypeLabel(order.type) + ' ' + order.crypto_persian_name }}
              </b-badge>
            </b-col>
            <b-col class="mt-1 sm">
              <span class="text-black-50">روش پرداخت : </span>
              <b-badge
                pill
                :variant="'light-'+orderPayMethodVariant(order.pay_method)"
                class="text-capitalize"
              >
                {{ orderPayMethodLabel(order.pay_method) }}
              </b-badge>
            </b-col>
            <b-col class="mt-1 sm">
              <span class="text-black-50">تاریخ سفارش: </span>
              <span> {{ order.jalali_created_at }}</span>
            </b-col>
          </b-row>

          <b-row>

            <b-col class="mt-2 sm">
              <span class="text-black-50"> آدرس کیف پول: </span>
              <span> {{ order.amount + ' ' + order.wallet_address }}</span>
            </b-col>

            <b-col
              class="mt-2 sm"
            >
              <span class="text-black-50">  توضیحات تراکنش: </span>
              <span> {{ order.transaction_description || 'توضیحات تراکنش ندارد' }}</span>
            </b-col>

            <b-col
              class="mt-2 sm"
            >
              <span class="text-black-50">  کد امنیتی:  </span>
              <span> {{ order.security_code || 'کد امنیتی ندارد' }}</span>
            </b-col>

            <b-col class="mt-2 sm">
              <span class="text-black-50">   توضیحات: </span>
              <span> {{ order.description }}</span>
            </b-col>

          </b-row>

          <b-row>
            <b-col class="mt-2 sm">
              <span class="text-black-50"> تعداد: </span>
              <span> {{ order.amount + ' ' + order.crypto_symbol }}</span>
            </b-col>

            <b-col
              v-if="order.type === 'buy'"
              class="mt-2 sm"
            >
              <span class="text-black-50"> قیمت واحد: </span>
              <span> {{ Number(order.purchase_price_from_us).toLocaleString() + ' ' + 'تومان' }}</span>
            </b-col>

            <b-col
              v-if="order.type === 'sell'"
              class="mt-2 sm"
            >
              <span class="text-black-50"> قیمت واحد:  </span>
              <span> {{ Number(order.sale_price_from_us).toLocaleString() + ' ' + 'تومان' }}</span>
            </b-col>

            <b-col class="mt-2 sm">
              <span class="text-black-50"> مبلغ کل پرداختی: </span>
              <span> {{ Number(order.cost).toLocaleString() + ' ' + 'تومان' }}</span>
            </b-col>

            <b-col class="mt-2 sm">
              <span class="text-black-50">   وضعیت سفارش : </span>
              <b-badge
                pill
                :variant="'light-'+orderStatusVariant(order.status)"
                class="text-capitalize"
              >
                {{ orderStatusLabel(order.status) }}
              </b-badge>
            </b-col>

          </b-row>

          <div v-if="order.pay_method === 'wallet'">
            <hr>

            <b-row
              class="mt-2"
            >
              <b-col>
                <h4>مشخصات تراکنش (کیف پول) </h4>
              </b-col>
            </b-row>
          </div>

          <b-row class="mt-2">

            <b-col class="mt-1 sm">
              <span class="text-black-50">  موجودی کیف پول کاربر: </span>
              <span>{{ Number(order.tomman_inventory).toLocaleString() }}</span>
            </b-col>

            <b-col class="mt-2 sm">
              <span class="text-black-50"> مبلغ کل سفارش : </span>
              <span> {{ Number(order.cost).toLocaleString() + ' ' + 'تومان' }}</span>
            </b-col>

          </b-row>

          <!--          action -->
          <div>
            <hr>
            <b-row
              v-if="order.pay_method === 'wallet'"
              class="mt-2"
            >
              <b-col>
                <h4>عملیات</h4>
              </b-col>

            </b-row>

            <b-row class="mt-2">

              <b-col>
                <span class="text-black-50">
                  وضعیت سفارش :
                </span>
              </b-col>

              <b-col>
                <v-select
                  v-model="selected"
                  dir="rtl"
                  :options="statusOption"
                  label="title"
                />
              </b-col>

              <b-col>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="changeStatus"
                >
                  ثبت تغییرات
                </b-button>
              </b-col>

            </b-row>

          </div>

        </div>
        <!--        order details system_payment sell-->
        <div v-if="order.method === 'system_payment' && order.type === 'sell'">

          <div>
            <b-row class="mt-2">
              <b-col>
                <h4>مشخصات سفارش </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col class="mt-1 sm">
                <span class="text-black-50">شماره سفارش: </span>
                <span> {{ order.order_number }}</span>
              </b-col>
              <b-col class="mt-1 sm">
                <span class="text-black-50">نوع سفارش: </span>
                <b-badge
                  pill
                  :variant="'light-'+orderTypeVariant(order.type)"
                  class="text-capitalize"
                >
                  {{ orderTypeLabel(order.type) + ' ' + order.crypto_persian_name }}
                </b-badge>
              </b-col>
              <b-col class="mt-1 sm">
                <span class="text-black-50">روش پرداخت : </span>
                <b-badge
                  pill
                  :variant="'light-'+orderPayMethodVariant(order.pay_method)"
                  class="text-capitalize"
                >
                  {{ orderPayMethodLabel(order.pay_method) }}
                </b-badge>
              </b-col>
              <b-col class="mt-1 sm">
                <span class="text-black-50">تاریخ سفارش: </span>
                <span> {{ order.jalali_created_at }}</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mt-2 sm">
                <span class="text-black-50"> تعداد: </span>
                <span> {{ order.amount + ' ' + order.crypto_symbol }}</span>
              </b-col>

              <b-col
                v-if="order.type === 'buy'"
                class="mt-2 sm"
              >
                <span class="text-black-50"> قیمت واحد: </span>
                <span> {{ Number(order.purchase_price_from_us).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

              <b-col
                v-if="order.type === 'sell'"
                class="mt-2 sm"
              >
                <span class="text-black-50"> قیمت واحد:  </span>
                <span> {{ Number(order.sale_price_from_us).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50"> مبلغ کل پرداختی: </span>
                <span> {{ Number(order.cost).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50">   وضعیت سفارش : </span>
                <b-badge
                  pill
                  :variant="'light-'+orderStatusVariant(order.status)"
                  class="text-capitalize"
                >
                  {{ orderStatusLabel(order.status) }}
                </b-badge>
              </b-col>

            </b-row>

            <b-row>
              <b-col
                v-if="order.type === 'buy'"
                class="mt-2 sm"
              >
                <span class="text-black-50">   آدرس کیف پول : </span>
                <span> {{ order.wallet_address || '----' }}</span>
              </b-col>
              <b-col class="mt-2 sm">
                <span class="text-black-50">     توضیحات پرداخت: </span>
                <span> {{ order.description || '----' }}</span>
              </b-col>
            </b-row>
          </div>

          <div v-if="order.pay_method === 'wallet'">

            <hr>

            <b-row
              class="mt-2"
            >
              <b-col>
                <h4>مشخصات تراکنش (کیف پول) </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col class="mt-1 sm">
                <span class="text-black-50">  موجودی کیف پول کاربر: </span>
                <span>{{ Number(order.tomman_inventory).toLocaleString() }}</span>
              </b-col>

              <b-col class="mt-2 sm">
                <span class="text-black-50"> مبلغ کل سفارش : </span>
                <span> {{ Number(order.cost).toLocaleString() + ' ' + 'تومان' }}</span>
              </b-col>

            </b-row>

          </div>

          <div v-if="order.pay_method === 'bank_account'">

            <hr>

            <b-row
              class="mt-2"
            >
              <b-col>
                <h4>مشخصات تراکنش (انتقال به کارت بانکی کاربر) </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col class="mt-1 sm">
                <span class="text-black-50">  نام بانک : </span>
                <span>{{ order.user_bank_name || 'ثبت نشده است!' }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره حساب: </span>
                <span>{{ order.user_account_number || 'ثبت نشده است!' }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره کارت: </span>
                <span>{{ order.user_card_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره شبا: </span>
                <span>{{ order.user_shaba_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">   وضعیت کارت بانکی کاربر: </span>
                <b-badge
                  pill
                  :variant="'light-'+bankAccountStatusVariant(order.user_bank_account_status)"
                  class="text-capitalize"
                >
                  {{ bankAccountStatusLabel(order.user_bank_account_status) }}
                </b-badge>
              </b-col>

            </b-row>

          </div>

          <div v-if="order.pay_method === 'other_account'">

            <hr>

            <b-row
              class="mt-2"
            >
              <b-col>
                <h4>مشخصات تراکنش (واریز به حساب دیگران) </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره کارت: </span>
                <span>{{ order.other_card_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  نام و فامیل صاحب حساب : </span>
                <span>{{ order.other_fullname }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">   نام بانک: </span>
                <span>{{ order.other_bank_name || 'ثبت نشده است!' }}</span>
              </b-col>

            </b-row>

            <b-row>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره شبا: </span>
                <span>{{ order.other_shaba_number }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">  شماره حساب: </span>
                <span>{{ order.other_account_number || 'ثبت نشده است!' }}</span>
              </b-col>

              <b-col class="mt-1 sm">
                <span class="text-black-50">   توضیحات: </span>
                <span>{{ order.description }}</span>
              </b-col>

            </b-row>

          </div>

          <!--          action -->
          <div>
            <hr>
            <b-row
              v-if="order.pay_method === 'wallet'"
              class="mt-2"
            >
              <b-col>
                <h4>عملیات</h4>
              </b-col>

            </b-row>

            <b-row class="mt-2">

              <b-col>
                <span class="text-black-50">
                  وضعیت سفارش :
                </span>
              </b-col>

              <b-col>
                <v-select
                  v-model="selected"
                  dir="rtl"
                  :options="statusOption"
                  label="title"
                />
              </b-col>

              <b-col>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="changeStatus"
                >
                  ثبت تغییرات
                </b-button>
              </b-col>

            </b-row>

          </div>

        </div>

        <!--        order details product_pruchase product_pruchase-->
        <div v-if="order.method === 'product_pruchase' && order.type === 'product_pruchase'" />
        <!--        <div v-if="order.method === 'system_payment' && order.type === 'sell'">-->

      </b-card>

    </b-overlay>

  </section>

</template>

<script>
import {
  BCard,
  BOverlay,
  BAlert,
  BLink,
  BRow,
  BCol,
  BBadge,
  BButton,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'OrderDetails',
  components: {
    BCard,
    BOverlay,
    BAlert,
    BLink,
    BRow,
    BCol,
    BBadge,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      voucherCost: '',
      order: null,
      selected: null,
      statusOption: [
        { title: 'تایید شده', value: 'confirm' },
        { title: 'رد شده', value: 'reject' },
        { title: 'در حال بررسی', value: 'processing' },
      ],
    }
  },
  computed: {
    orderTypeLabel() {
      const a = {
        buy: 'خرید',
        sell: 'فروش',
        charge: 'شارژ',
        product_pruchase: 'خرید محصول',
        handleOrderStatus: {
          processing: { name: 'در حال بررسی' },
          confirm: { name: 'موفق' },
          reject: { name: 'ناموفق' },
        },
      }

      return e => a[e]
    },
    orderTypeVariant() {
      const a = {
        buy: 'success',
        sell: 'danger',
        charge: 'info',
        product_pruchase: 'primary',
      }
      return e => a[e]
    },
    orderPayMethodLabel() {
      const a = {
        wallet: 'انتقاب به کیف پول',
        bank_account: 'واریز به حساب بانکی',
        other_account: 'واریز به حساب دیگران',
      }

      return e => a[e]
    },
    orderPayMethodVariant() {
      const a = {
        wallet: 'primary',
        bank_account: 'info',
        other_account: 'dark',
      }
      return e => a[e]
    },
    accountStatusVariant() {
      const a = {
        confirmed: 'success',
        processing: 'warning',
        reject: 'danger',
        not_complete: 'info',
      }
      return e => a[e]
    },
    accountStatusLabel() {
      const a = {
        confirmed: 'تایید شده',
        processing: 'در حال بررسی',
        reject: 'رد شده',
        not_complete: 'کامل نشده',
      }
      return e => a[e]
    },
    orderStatusLabel() {
      const a = {
        processing: 'در حال بررسی',
        confirm: 'تایید شده',
        reject: 'رد شده',
      }
      return e => a[e]
    },
    orderStatusVariant() {
      const a = {
        confirm: 'success',
        processing: 'warning',
        reject: 'danger',
      }
      return e => a[e]
    },
    bankAccountStatusVariant() {
      const a = {
        confirm: 'success',
        processing: 'warning',
        reject: 'danger',
      }
      return e => a[e]
    },
    bankAccountStatusLabel() {
      const a = {
        processing: 'در حال بررسی',
        confirm: 'تایید شده',
        reject: 'رد شده',
      }
      return e => a[e]
    },
  },
  async mounted() {
    await this.getOrder()
  },
  methods: {
    async getOrder() {
      this.loading = true
      const response = await this.$axios(`/panel/order/${this.$route.params.id}`)
      this.order = response.data
      this.selected = { value: response.data.status, title: this.orderStatusLabel(response.data.status) }
      this.loading = false
    },
    async changeStatus() {
      this.loading = true
      const response = await this.$axios(`/panel/order/change-status/${this.order.id}?status=${this.selected.value}`)
      console.log(response)
      this.getOrder()
      this.loading = false
      console.log(response.data)
      if (response.data.status) {
        this.$swal({
          title: 'عملیات موفق!',
          text: response.data.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.$swal({
          title: 'خطایی رخ داده است!',
          text: response.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    async changeVoucherStatus() {
      if (this.order.pay_method === 'wallet' && !this.voucherCost) return

      const data = {
        cost: this.voucherCost,
      }
      this.loading = true
      const response = await this.$axios({
        url: `/panel/order/change-status/${this.order.id}?status=${this.selected.value}`,
        method: 'POST',
        data,
      })
      console.log(response.data)
      this.getOrder()
      this.loading = false
      if (response.data.status) {
        this.$swal({
          title: 'عملیات موفق!',
          text: response.data.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.$swal({
          title: 'خطایی رخ داده است!',
          text: response.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
